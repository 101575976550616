import React, { useState, useEffect } from "react";
import Card from "../components/card";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useParams } from "react-router-dom";

function EditDevice() {

    const navigate = useNavigate();
    const { getToken } = useKindeAuth();
    const [isDeleting, setIsDeleting] = useState(false);
    const [device, setDevice] = useState({});
    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const { buildingId, deviceId } = useParams();

    const getDevice = async() =>{
        const accessToken = await getToken();
        axios.get(`${process.env.REACT_APP_API_URL}/v1/devices/${deviceId}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(res => {
                const apiDevice = res.data;
                setDevice(apiDevice);
        });
    }

    useEffect(() => {
        const getDevice = async () => {
            try {
                const accessToken = await getToken();
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/devices/${deviceId}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const apiDevice = response.data;
                setDevice(apiDevice);
                setFormData({
                    name: apiDevice.name || '',
                    building: apiDevice.building || '',
                    description: apiDevice.description || '',
                    deviceId: apiDevice.deviceId || '',
                    sensorId: apiDevice.sensorId || '',
                    firmwareId: apiDevice.firmwareId || '',
                    measurementType: apiDevice.measurementType || '',
                    category: apiDevice.category || '',
                    measurementTypeUnit: apiDevice.measurementTypeUnit || '',
                    topic: apiDevice.topic || '',
                    // Add more fields as needed
                });
                setIsLoading(false);
            } catch (error) {
                console.error('Error:', error);
            }
        }

        getDevice();
    }, [deviceId, getToken]);

    const handleInputChange = (e) => {
        // Update form data state as the user types.
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleSensorTypeChange = (event) => {
        const selectedType = event.target.value;
        let unit = "";
    
        if (selectedType === "temp") {
            unit = "℃";
        }else if (selectedType === "energy") {
            unit = "kWh";
        }else if (selectedType === "digital"){
            unit = ""; // Set default unit for other sensor types
        }
        else {
            unit = ""; // Set default unit for other sensor types
        }
        setFormData((prevFormData) => ({
            ...prevFormData,
            measurementType: selectedType,
            measurementTypeUnit: unit
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const accessToken = await getToken();

        try {
            // Send a POST request with Axios
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/v1/devices/${device._id}`, formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            console.log('Response:', response.data);
            
            // Get the _id from the response
            const deviceId = response.data._id;
            
            // Construct the redirect URL
            const redirectUrl = `/buildings/${buildingId}/devices/`;
            console.log('Skal videresendes til:', redirectUrl);

            // Use the navigate function to redirect to the new URL
            navigate(redirectUrl);
        } catch (error) {
            // Handle errors here
            console.error('Error:', error);
        }
    };

    return(
        <div className="container-fluid">
            <Card variant="light" title="Rediger Sensor" size="12">
                <form className="create-building-form" id="createBuildingForm">
                        <div className="triple-form">
                            <h3>Enhetsinformasjon</h3>
                            <input type="text" hidden name="_id" value={device._id} readonly />
                            <input type="text" hidden readonly value={device.organization} name="organization" />

                            <label for="name"><i className="fa-sharp fa-light fa-database"></i> Enhetsnavn</label>
                            <input onChange={handleInputChange} defaultValue={device.name} type="text" name="name" placeholder="Enhetsnavn" className="form-control" />

                            <label for="sensorId"><i className="fa-sharp fa-light fa-database"></i> Sensor ID*</label>
                            <input onChange={handleInputChange} type="text" defaultValue={device.sensorId} name="sensorId" placeholder="#323232" className="form-control" />

                            <label for="deviceId"><i className="fa-sharp fa-light fa-database"></i> Enhets ID</label>
                            <input onChange={handleInputChange} defaultValue={device.deviceId} type="text" name="deviceId" placeholder="#23232" className="form-control" />


                            <label for="description"><i className="fa-sharp fa-light fa-database"></i> Beskrivelse</label>
                            <textarea placeholder="Skriv en beskrivelse her..." defaultValue={device.description}onChange={handleInputChange} name="description" className="form-control">
                            </textarea>
                        </div>

                        <div className="triple-form">
                            <h3>API / databroker</h3>
                            <label for="topic">Topic</label>
                            <input onChange={handleInputChange} defaultValue={device.topic} type="text" name="topic" placeholder="topic/eksempel/sensorId" className="form-control" />

                            <label htmlFor="sensorType">Sensortype</label>
                            <select onChange={handleSensorTypeChange} value={formData.measurementType} name="measurementType" className="form-select">
                                <option value="temp">Sanntidsmåling</option>
                                <option value="energy">Akkumulert</option> {/* Add other sensor types as needed */}
                                <option value="digital">Digital (1/0)</option> 
                            </select>

                            <label htmlFor="sensorTypeUnit">Sensortype enhet</label>
                            <input value={formData.measurementTypeUnit} type="text" name="measurementTypeUnit" className="form-control"/>
                            {formData.measurementType === "energy" && (
                                <>
                                <label>Energimåling kategori</label>
                                <select onChange={handleInputChange} value={formData.category} name="category" className="form-control">
                                    <option hidden value="">Velg Kategori...</option>
                                    <option value="">Ingen kategori</option>
                                    <option value="Oppvarming">Oppvarming</option>
                                    <option value="Varmtvann">Varmtvann</option>
                                    <option value="Vifter og pumper">Vifter og pumper</option>
                                    <option value="Belysning">Belysning</option>
                                    <option value="Teknisk utstyr">Teknisk utstyr</option>
                                    <option value="Kjøling">Kjøling</option>
                                    <option value="Utendørs">Utendørs</option>
                                </select>
                                </>
                            )}
                        </div>

                        <div className="triple-form">
                        </div>
                    </form>
            </Card>

            <div className="col-sm-12">
                <button type="submit" onClick={handleFormSubmit} className="button create">Oppdater</button>
            </div>
        </div>
    )

}

export default EditDevice;