// GlobalStateContext.js
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
const GlobalStateContext = createContext();

export const useGlobalState = () => useContext(GlobalStateContext);

export const GlobalStateProvider = ({ children }) => {

  // Import moment library for easier date formating and calculations
  const moment = require('moment');
  const today = new Date();
  var formatToday = moment(today).format('YYYY-MM-DD');
  
  const [dateFilter, setDateFilter] = useState({ startDate: formatToday, endDate: formatToday });
  
  const updateDateFilter = (startDate, endDate) => {
    setDateFilter({ startDate, endDate });
  };
  
  const setStartDate = (newStartDate) => {
    if (newStartDate>=dateFilter.endDate) {
      // Don't update if endDate is before newStartDate
      setDateFilter({ startDate: newStartDate, endDate: newStartDate });
      return;
    }

    setDateFilter({ ...dateFilter, startDate:newStartDate });
  };

  const setEndDate = (newEndDate) => {
    if (newEndDate && dateFilter.startDate && newEndDate < dateFilter.startDate) {
      // Don't update if newEndDate is before startDate
      setDateFilter({startDate:newEndDate, endDate:newEndDate})
      return;
    }

    setDateFilter({ ...dateFilter, endDate:newEndDate });
  };
  
  // user state 
  const { user, getToken } = useKindeAuth();
  const setUser = async () => {
    const accessToken = await getToken();
    if (user !== undefined && accessToken !== undefined){
    
            axios.post(`${process.env.REACT_APP_API_URL}/v1/login-heartbeat`,{
                name : user.given_name + " " + user.family_name,
                email : user.email,
            },
            {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
            }).then((res)=> {
                setAquaflowUser(res.data);
            })
      
        
        
    }else{
      setAquaflowUser({user:{
          organizations: [],
          kindeId: "",
          email: "",
          name: "",
          id: ""
      }});
    }
  }
  
  const refreshUser = () => {
    setUser();
    return {};
  }
  const [aquaflowUser, setAquaflowUser] = useState(refreshUser);
  const [triggeredAlarmsCount , setTriggeredAlarms] = useState(0);
  const refreshTriggeredAlarms = async()=> {
    const accessToken = await getToken();
    axios
        .get(`${process.env.REACT_APP_API_URL}/v1/alarms?triggered=true`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        .then((res) => {
            setTriggeredAlarms(res.data.length);
        });
  }
  return (
    <GlobalStateContext.Provider value={{
      aquaflowUser, 
      dateFilter, 
      triggeredAlarmsCount,
      updateDateFilter, 
      setStartDate, 
      setEndDate, 
      refreshUser,
      refreshTriggeredAlarms,
      }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
