import React, { useState } from "react";
import Card from "../components/card";
import axios from 'axios';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../GlobalStateContext";

function CreateBuilding() {

    const navigate = useNavigate();
    const { getToken } = useKindeAuth();
    const [numRooms, setNumRooms] = useState(0);
    const [building, setBuilding] = useState([]);
    const { aquaflowUser } = useGlobalState();

    const [formData, setFormData] = useState({
        // Initialize form fields here
        name: '',
        organization: '',
        address: '',
        city: '',
        zip: '',
        totalFloors: '',
        sizeBra: '',
        sizeBta: '',
        devices: [],
        rooms: [
            {
                devices: [],
                name: "",
                floor: "",
            },
        ],
        miscEmployees: [],
        // Add more fields as needed
      });

    const handleClickAddRules = (e) => {
        e.preventDefault();
        setNumRooms(numRooms + 1);
    }

    const handleClickSubtractRules = (e) => {
        setNumRooms(numRooms - 1);
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const accessToken = await getToken();

        try {
            // Send a POST request with Axios
            const response = await axios.post(process.env.REACT_APP_API_URL+'/v1/buildings', formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            console.log('Response:', response.data);
            
            // Get the _id from the response
            const buildingId = response.data._id;
            
            // Construct the redirect URL
            const redirectUrl = `/buildings/${buildingId}/devices/`;
            console.log('Skal videresendes til:', redirectUrl);

            // Use the navigate function to redirect to the new URL
            navigate(redirectUrl);
        } catch (error) {
            // Handle errors here
            console.error('Error:', error);
        }
    };

    const handleInputChange = (e) => {
        // Update form data state as user types.
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        console.log(formData);
    };


    return(
        <div className="container-fluid">
            <div className="row">
                <Card variant="light" size="12" title="Opprett bygg">
                    <form onSubmit={handleFormSubmit} className="create-building-form" id="createBuildingForm">
                        <div className="triple-form">
                            <h3>Bygginformasjon</h3>

                            <label for="organization"> Organisasjon</label>
                            {console.log(aquaflowUser)}
                            <select name="organization" className="form-select" onChange={handleInputChange}>
                                <option disabled selected value="">Velg organisasjon...</option>
                                {aquaflowUser.user.organizations.map((org => {
                                    return(
                                        <option value={org._id}>{org.name}</option>
                                    );
                                }))}
                            </select>

                            <label for="name"><i className="fa-sharp fa-light fa-building"></i> Byggnavn / firmanavn</label>
                            <input onChange={handleInputChange} type="text" name="name" placeholder="Firmabygg AS" defaultValue={formData.building_name} className="form-control" />


                            <label for="address"><i className="fa-sharp fa-light fa-location-dot"></i> Adresse</label>
                            <input onChange={handleInputChange} type="text" name="address" placeholder="Firmaveien 32" defaultValue={formData.building_adress} className="form-control" />

                            <div className="post-flex">
                                <div className="post-flex-item">
                                    <label for="city"><i className="fa-sharp fa-light fa-location-dot"></i> Poststed</label>
                                    <input onChange={handleInputChange} type="text" name="city" placeholder="Drammen" defaultValue={formData.building_city} className="form-control" />
                                </div>

                                <div className="post-flex-item">
                                    <label for="zip">Postnr.</label>
                                    <input onChange={handleInputChange} type="text" name="zip" placeholder="3004" defaultValue={formData.building_zip} className="form-control" />
                                </div>
                            </div>
                        </div>

                        <div className="triple-form">
                            <h3>Byggdetaljer</h3>

                            <label for="totalFloors"><i class="fa-sharp fa-light fa-buildings"></i> Etasjer</label>
                            <input onChange={handleInputChange} type="number" name="totalFloors" defaultValue="1" className="form-control" />

                            <label for="sizeBra"><i className="fa-sharp fa-light fa-arrow-up-right-and-arrow-down-left-from-center"></i> Størrelse (BRA)</label>
                            <input onChange={handleInputChange} type="text" name="sizeBra" placeholder="1600 m²" defaultValue={formData.building_size} className="form-control" />

                            <label for="sizeBta"><i className="fa-sharp fa-light fa-arrow-up-right-and-arrow-down-left-from-center"></i> Størrelse (BTA)</label>
                            <input onChange={handleInputChange} type="text" name="sizeBta" placeholder="1400 m²" defaultValue={formData.building_size_2} className="form-control" />

                        </div>

                        <div className="triple-form">
                            <h3>Rom</h3>

                            {Array.from(Array(numRooms), (e, i) => {
                                return(
                                    <div>
                                        <label for={"b-room-" + i}><i className="fa-sharp fa-regular fa-square-dashed"></i> Rom</label>
                                        <input type="text" name={"b-room-" + i} placeholder="Navn på rommet..." className="form-control" />
                                    </div>
                                )
                            })}

                            <button className="small-text-add" onClick={handleClickAddRules}><i className="fa-sharp fa-regular fa-plus"></i> Legg til rom</button>

                        </div>

                    </form>
                </Card>

                <div className="col-sm-12">
                    <button type="submit" onClick={(e)=>{e.preventDefault();handleFormSubmit(e)}} className="button create"><i className="fa-sharp fa-regular fa-plus"></i> Opprett bygg</button>
                </div>
            </div>
        </div>
    );
}

export default CreateBuilding;